import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _287b0812 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2b8590ce = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _d13db536 = () => interopDefault(import('../pages/refer-a-friend/index.vue' /* webpackChunkName: "pages/refer-a-friend/index" */))
const _31d53ee5 = () => interopDefault(import('../pages/shops/index.vue' /* webpackChunkName: "pages/shops/index" */))
const _945fdbda = () => interopDefault(import('../pages/lookbooks/index.vue' /* webpackChunkName: "pages/lookbooks/index" */))
const _54d2bc5e = () => interopDefault(import('../pages/marque/index.vue' /* webpackChunkName: "pages/marque/index" */))
const _2644e336 = () => interopDefault(import('../pages/loyalty-program/index.vue' /* webpackChunkName: "pages/loyalty-program/index" */))
const _a811c206 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _b4ef038c = () => interopDefault(import('../pages/account/address/index.vue' /* webpackChunkName: "pages/account/address/index" */))
const _906bf346 = () => interopDefault(import('../pages/account/autologin.vue' /* webpackChunkName: "pages/account/autologin" */))
const _b88d79cc = () => interopDefault(import('../pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _1bca82f4 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _94d6bb96 = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _d3d928a6 = () => interopDefault(import('../pages/account/referral/index.vue' /* webpackChunkName: "pages/account/referral/index" */))
const _1be134c9 = () => interopDefault(import('../pages/account/returns/index.vue' /* webpackChunkName: "pages/account/returns/index" */))
const _821a288a = () => interopDefault(import('../pages/account/service/index.vue' /* webpackChunkName: "pages/account/service/index" */))
const _3ab4fadc = () => interopDefault(import('../pages/cart/connection.vue' /* webpackChunkName: "pages/cart/connection" */))
const _77ea08b0 = () => interopDefault(import('../pages/cart/delivery.vue' /* webpackChunkName: "pages/cart/delivery" */))
const _191651fc = () => interopDefault(import('../pages/cart/payment.vue' /* webpackChunkName: "pages/cart/payment" */))
const _f01c833e = () => interopDefault(import('../pages/content/cgv.vue' /* webpackChunkName: "pages/content/cgv" */))
const _79c824b0 = () => interopDefault(import('../pages/content/faq/index.vue' /* webpackChunkName: "pages/content/faq/index" */))
const _dcefdaa6 = () => interopDefault(import('../pages/content/legal-notice.vue' /* webpackChunkName: "pages/content/legal-notice" */))
const _b1d6d4d0 = () => interopDefault(import('../pages/content/privacy-policy.vue' /* webpackChunkName: "pages/content/privacy-policy" */))
const _01d3cb58 = () => interopDefault(import('../pages/account/informations.vue' /* webpackChunkName: "pages/account/informations" */))
const _b82a12d2 = () => interopDefault(import('../pages/password/new.vue' /* webpackChunkName: "pages/password/new" */))
const _301edf06 = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _4e2f89af = () => interopDefault(import('../pages/password/validate.vue' /* webpackChunkName: "pages/password/validate" */))
const _b92ac7f0 = () => interopDefault(import('../pages/account/address/new.vue' /* webpackChunkName: "pages/account/address/new" */))
const _a4235820 = () => interopDefault(import('../pages/account/components/GetCardCadeau/index.vue' /* webpackChunkName: "pages/account/components/GetCardCadeau/index" */))
const _0af8dcda = () => interopDefault(import('../pages/account/service/tickets/index.vue' /* webpackChunkName: "pages/account/service/tickets/index" */))
const _78147334 = () => interopDefault(import('../pages/cart/components/CartItemCodesPromo/index.vue' /* webpackChunkName: "pages/cart/components/CartItemCodesPromo/index" */))
const _7bb270d8 = () => interopDefault(import('../pages/account/components/GetCardCadeau/GetCardCadeauShow.vue' /* webpackChunkName: "pages/account/components/GetCardCadeau/GetCardCadeauShow" */))
const _44599a5e = () => interopDefault(import('../pages/account/components/GetCardCadeau/GetCardCadeauStep1.vue' /* webpackChunkName: "pages/account/components/GetCardCadeau/GetCardCadeauStep1" */))
const _4467b1df = () => interopDefault(import('../pages/account/components/GetCardCadeau/GetCardCadeauStep2.vue' /* webpackChunkName: "pages/account/components/GetCardCadeau/GetCardCadeauStep2" */))
const _0f028c17 = () => interopDefault(import('../pages/cart/components/CartItemCodesPromo/CodePromoItem.vue' /* webpackChunkName: "pages/cart/components/CartItemCodesPromo/CodePromoItem" */))
const _1a805346 = () => interopDefault(import('../pages/cart/components/payments/alma/AlmaForm.vue' /* webpackChunkName: "pages/cart/components/payments/alma/AlmaForm" */))
const _a4656e1a = () => interopDefault(import('../pages/cart/components/payments/alma/AlmaFormHeader.vue' /* webpackChunkName: "pages/cart/components/payments/alma/AlmaFormHeader" */))
const _25482451 = () => interopDefault(import('../pages/cart/components/payments/hipay/BancCardHipay/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/BancCardHipay/index" */))
const _aab9ec0e = () => interopDefault(import('../pages/cart/components/payments/hipay/Bancontact/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Bancontact/index" */))
const _00bc41b7 = () => interopDefault(import('../pages/cart/components/payments/hipay/Giropay/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Giropay/index" */))
const _660e9e23 = () => interopDefault(import('../pages/cart/components/payments/hipay/Ideal/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Ideal/index" */))
const _77a55e22 = () => interopDefault(import('../pages/cart/components/payments/hipay/Sofort/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Sofort/index" */))
const _4adcdf06 = () => interopDefault(import('../pages/cart/components/payments/klarna/KlarnaForm.vue' /* webpackChunkName: "pages/cart/components/payments/klarna/KlarnaForm" */))
const _4ffbf69a = () => interopDefault(import('../pages/cart/components/payments/klarna/KlarnaFormHeader.vue' /* webpackChunkName: "pages/cart/components/payments/klarna/KlarnaFormHeader" */))
const _00908f26 = () => interopDefault(import('../pages/cart/components/payments/paypal/PayPalForm.vue' /* webpackChunkName: "pages/cart/components/payments/paypal/PayPalForm" */))
const _3048be00 = () => interopDefault(import('../pages/cart/components/payments/paypal/PayPalForm-old.vue' /* webpackChunkName: "pages/cart/components/payments/paypal/PayPalForm-old" */))
const _3634dcf3 = () => interopDefault(import('../pages/cart/components/payments/paypal/PaypalFormHeader.vue' /* webpackChunkName: "pages/cart/components/payments/paypal/PaypalFormHeader" */))
const _522a63f4 = () => interopDefault(import('../pages/cart/components/payments/stripe/StripeForm.vue' /* webpackChunkName: "pages/cart/components/payments/stripe/StripeForm" */))
const _57a0b3b3 = () => interopDefault(import('../pages/cart/components/payments/stripe/StripeFormHeader.vue' /* webpackChunkName: "pages/cart/components/payments/stripe/StripeFormHeader" */))
const _7aec7f8f = () => interopDefault(import('../pages/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader" */))
const _6aeb0d41 = () => interopDefault(import('../pages/cart/components/payments/hipay/BancCardHipay/BankCardForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/BancCardHipay/BankCardForm" */))
const _0803c6fe = () => interopDefault(import('../pages/cart/components/payments/hipay/Bancontact/BanContactForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Bancontact/BanContactForm" */))
const _36732032 = () => interopDefault(import('../pages/cart/components/payments/hipay/Bancontact/BancontactHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Bancontact/BancontactHeader" */))
const _508d1f92 = () => interopDefault(import('../pages/cart/components/payments/hipay/Giropay/GiropayForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Giropay/GiropayForm" */))
const _1b20335b = () => interopDefault(import('../pages/cart/components/payments/hipay/Giropay/GiropayHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Giropay/GiropayHeader" */))
const _5ceec3ea = () => interopDefault(import('../pages/cart/components/payments/hipay/Ideal/IdealForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Ideal/IdealForm" */))
const _d4abbc9a = () => interopDefault(import('../pages/cart/components/payments/hipay/Ideal/IdealHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Ideal/IdealHeader" */))
const _f232ff44 = () => interopDefault(import('../pages/cart/components/payments/hipay/Sofort/SofortForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Sofort/SofortForm" */))
const _20a49127 = () => interopDefault(import('../pages/cart/components/payments/hipay/Sofort/SofortHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Sofort/SofortHeader" */))
const _cc099e96 = () => interopDefault(import('../pages/account/service/request/_productAttribute.vue' /* webpackChunkName: "pages/account/service/request/_productAttribute" */))
const _31618348 = () => interopDefault(import('../pages/account/service/tickets/_idTickets.vue' /* webpackChunkName: "pages/account/service/tickets/_idTickets" */))
const _2a730ebc = () => interopDefault(import('../pages/account/address/_idAddress.vue' /* webpackChunkName: "pages/account/address/_idAddress" */))
const _8240a446 = () => interopDefault(import('../pages/account/orders/_id.vue' /* webpackChunkName: "pages/account/orders/_id" */))
const _07e915ab = () => interopDefault(import('../pages/account/return/_id/index.vue' /* webpackChunkName: "pages/account/return/_id/index" */))
const _bbde7998 = () => interopDefault(import('../pages/account/returns/_id/index.vue' /* webpackChunkName: "pages/account/returns/_id/index" */))
const _8e39c4f4 = () => interopDefault(import('../pages/content/faq/_permalink.vue' /* webpackChunkName: "pages/content/faq/_permalink" */))
const _43089938 = () => interopDefault(import('../pages/account/return/_id/confirmed.vue' /* webpackChunkName: "pages/account/return/_id/confirmed" */))
const _4c8738c2 = () => interopDefault(import('../pages/cart/thanks/_state/_idorder.vue' /* webpackChunkName: "pages/cart/thanks/_state/_idorder" */))
const _fcde63b4 = () => interopDefault(import('../pages/account/_tab/index.vue' /* webpackChunkName: "pages/account/_tab/index" */))
const _45a295ae = () => interopDefault(import('../pages/authenticate/_route.vue' /* webpackChunkName: "pages/authenticate/_route" */))
const _1851e883 = () => interopDefault(import('../pages/blog/_uid.vue' /* webpackChunkName: "pages/blog/_uid" */))
const _355d8715 = () => interopDefault(import('../pages/blogs/_permalink.vue' /* webpackChunkName: "pages/blogs/_permalink" */))
const _533dbb8a = () => interopDefault(import('../pages/shop/_permalink.vue' /* webpackChunkName: "pages/shop/_permalink" */))
const _7bbc03fb = () => interopDefault(import('../pages/lookbooks/_permalink.vue' /* webpackChunkName: "pages/lookbooks/_permalink" */))
const _6e8f0bbb = () => interopDefault(import('../pages/product/_product.vue' /* webpackChunkName: "pages/product/_product" */))
const _7d180885 = () => interopDefault(import('../pages/referral/_code.vue' /* webpackChunkName: "pages/referral/_code" */))
const _dd22d050 = () => interopDefault(import('../pages/shop-the-look/_product.vue' /* webpackChunkName: "pages/shop-the-look/_product" */))
const _7e7fdc70 = () => interopDefault(import('../pages/account/_tab/_tab2.vue' /* webpackChunkName: "pages/account/_tab/_tab2" */))
const _036f07f6 = () => interopDefault(import('../pages/_cat/index.vue' /* webpackChunkName: "pages/_cat/index" */))
const _b3596a66 = () => interopDefault(import('../pages/_cat/_product.vue' /* webpackChunkName: "pages/_cat/_product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _287b0812,
    name: "index___de"
  }, {
    path: "/en",
    component: _287b0812,
    name: "index___en"
  }, {
    path: "/fr",
    component: _287b0812,
    name: "index___fr"
  }, {
    path: "/de/cart",
    component: _2b8590ce,
    name: "cart___de"
  }, {
    path: "/de/empfehlen-sie-einen-freund",
    component: _d13db536,
    name: "refer-a-friend___de"
  }, {
    path: "/de/geschafte",
    component: _31d53ee5,
    name: "shops___de"
  }, {
    path: "/de/lookbooks",
    component: _945fdbda,
    name: "lookbooks___de"
  }, {
    path: "/de/marque",
    component: _54d2bc5e,
    name: "marque___de"
  }, {
    path: "/de/programm-fidelite",
    component: _2644e336,
    name: "loyalty-program___de"
  }, {
    path: "/de/test",
    component: _a811c206,
    name: "test___de"
  }, {
    path: "/en/cart",
    component: _2b8590ce,
    name: "cart___en"
  }, {
    path: "/en/lookbooks",
    component: _945fdbda,
    name: "lookbooks___en"
  }, {
    path: "/en/loyalty-program",
    component: _2644e336,
    name: "loyalty-program___en"
  }, {
    path: "/en/marque",
    component: _54d2bc5e,
    name: "marque___en"
  }, {
    path: "/en/refer-a-friend",
    component: _d13db536,
    name: "refer-a-friend___en"
  }, {
    path: "/en/shops",
    component: _31d53ee5,
    name: "shops___en"
  }, {
    path: "/en/test",
    component: _a811c206,
    name: "test___en"
  }, {
    path: "/fr/boutiques",
    component: _31d53ee5,
    name: "shops___fr"
  }, {
    path: "/fr/cart",
    component: _2b8590ce,
    name: "cart___fr"
  }, {
    path: "/fr/lookbooks",
    component: _945fdbda,
    name: "lookbooks___fr"
  }, {
    path: "/fr/marque",
    component: _54d2bc5e,
    name: "marque___fr"
  }, {
    path: "/fr/parrainer-un-ami",
    component: _d13db536,
    name: "refer-a-friend___fr"
  }, {
    path: "/fr/programme-fidelite",
    component: _2644e336,
    name: "loyalty-program___fr"
  }, {
    path: "/fr/test",
    component: _a811c206,
    name: "test___fr"
  }, {
    path: "/de/account/address",
    component: _b4ef038c,
    name: "account-address___de"
  }, {
    path: "/de/account/autologin",
    component: _906bf346,
    name: "account-autologin___de"
  }, {
    path: "/de/account/favorites",
    component: _b88d79cc,
    name: "account-favorites___de"
  }, {
    path: "/de/account/newsletter",
    component: _1bca82f4,
    name: "account-newsletter___de"
  }, {
    path: "/de/account/orders",
    component: _94d6bb96,
    name: "account-orders___de"
  }, {
    path: "/de/account/referral",
    component: _d3d928a6,
    name: "account-referral___de"
  }, {
    path: "/de/account/returns",
    component: _1be134c9,
    name: "account-returns___de"
  }, {
    path: "/de/account/service",
    component: _821a288a,
    name: "account-service___de"
  }, {
    path: "/de/cart/connection",
    component: _3ab4fadc,
    name: "cart-connection___de"
  }, {
    path: "/de/cart/delivery",
    component: _77ea08b0,
    name: "cart-delivery___de"
  }, {
    path: "/de/cart/payment",
    component: _191651fc,
    name: "cart-payment___de"
  }, {
    path: "/de/content/cgv",
    component: _f01c833e,
    name: "content-cgv___de"
  }, {
    path: "/de/content/faq",
    component: _79c824b0,
    name: "content-faq___de"
  }, {
    path: "/de/content/legal-notice",
    component: _dcefdaa6,
    name: "content-legal-notice___de"
  }, {
    path: "/de/content/privacy-policy",
    component: _b1d6d4d0,
    name: "content-privacy-policy___de"
  }, {
    path: "/de/konto/information",
    component: _01d3cb58,
    name: "account-informations___de"
  }, {
    path: "/de/password/new",
    component: _b82a12d2,
    name: "password-new___de"
  }, {
    path: "/de/password/reset",
    component: _301edf06,
    name: "password-reset___de"
  }, {
    path: "/de/password/validate",
    component: _4e2f89af,
    name: "password-validate___de"
  }, {
    path: "/en/account/address",
    component: _b4ef038c,
    name: "account-address___en"
  }, {
    path: "/en/account/autologin",
    component: _906bf346,
    name: "account-autologin___en"
  }, {
    path: "/en/account/favorites",
    component: _b88d79cc,
    name: "account-favorites___en"
  }, {
    path: "/en/account/informations",
    component: _01d3cb58,
    name: "account-informations___en"
  }, {
    path: "/en/account/newsletter",
    component: _1bca82f4,
    name: "account-newsletter___en"
  }, {
    path: "/en/account/orders",
    component: _94d6bb96,
    name: "account-orders___en"
  }, {
    path: "/en/account/referral",
    component: _d3d928a6,
    name: "account-referral___en"
  }, {
    path: "/en/account/returns",
    component: _1be134c9,
    name: "account-returns___en"
  }, {
    path: "/en/account/service",
    component: _821a288a,
    name: "account-service___en"
  }, {
    path: "/en/cart/connection",
    component: _3ab4fadc,
    name: "cart-connection___en"
  }, {
    path: "/en/cart/delivery",
    component: _77ea08b0,
    name: "cart-delivery___en"
  }, {
    path: "/en/cart/payment",
    component: _191651fc,
    name: "cart-payment___en"
  }, {
    path: "/en/content/cgv",
    component: _f01c833e,
    name: "content-cgv___en"
  }, {
    path: "/en/content/faq",
    component: _79c824b0,
    name: "content-faq___en"
  }, {
    path: "/en/content/legal-notice",
    component: _dcefdaa6,
    name: "content-legal-notice___en"
  }, {
    path: "/en/content/privacy-policy",
    component: _b1d6d4d0,
    name: "content-privacy-policy___en"
  }, {
    path: "/en/password/new",
    component: _b82a12d2,
    name: "password-new___en"
  }, {
    path: "/en/password/reset",
    component: _301edf06,
    name: "password-reset___en"
  }, {
    path: "/en/password/validate",
    component: _4e2f89af,
    name: "password-validate___en"
  }, {
    path: "/fr/account/address",
    component: _b4ef038c,
    name: "account-address___fr"
  }, {
    path: "/fr/account/autologin",
    component: _906bf346,
    name: "account-autologin___fr"
  }, {
    path: "/fr/account/favorites",
    component: _b88d79cc,
    name: "account-favorites___fr"
  }, {
    path: "/fr/account/informations",
    component: _01d3cb58,
    name: "account-informations___fr"
  }, {
    path: "/fr/account/newsletter",
    component: _1bca82f4,
    name: "account-newsletter___fr"
  }, {
    path: "/fr/account/orders",
    component: _94d6bb96,
    name: "account-orders___fr"
  }, {
    path: "/fr/account/referral",
    component: _d3d928a6,
    name: "account-referral___fr"
  }, {
    path: "/fr/account/returns",
    component: _1be134c9,
    name: "account-returns___fr"
  }, {
    path: "/fr/account/service",
    component: _821a288a,
    name: "account-service___fr"
  }, {
    path: "/fr/cart/connection",
    component: _3ab4fadc,
    name: "cart-connection___fr"
  }, {
    path: "/fr/cart/delivery",
    component: _77ea08b0,
    name: "cart-delivery___fr"
  }, {
    path: "/fr/cart/payment",
    component: _191651fc,
    name: "cart-payment___fr"
  }, {
    path: "/fr/content/cgv",
    component: _f01c833e,
    name: "content-cgv___fr"
  }, {
    path: "/fr/content/faq",
    component: _79c824b0,
    name: "content-faq___fr"
  }, {
    path: "/fr/content/legal-notice",
    component: _dcefdaa6,
    name: "content-legal-notice___fr"
  }, {
    path: "/fr/content/privacy-policy",
    component: _b1d6d4d0,
    name: "content-privacy-policy___fr"
  }, {
    path: "/fr/password/new",
    component: _b82a12d2,
    name: "password-new___fr"
  }, {
    path: "/fr/password/reset",
    component: _301edf06,
    name: "password-reset___fr"
  }, {
    path: "/fr/password/validate",
    component: _4e2f89af,
    name: "password-validate___fr"
  }, {
    path: "/de/account/address/new",
    component: _b92ac7f0,
    name: "account-address-new___de"
  }, {
    path: "/de/account/components/GetCardCadeau",
    component: _a4235820,
    name: "account-components-GetCardCadeau___de"
  }, {
    path: "/de/account/service/tickets",
    component: _0af8dcda,
    name: "account-service-tickets___de"
  }, {
    path: "/de/cart/components/CartItemCodesPromo",
    component: _78147334,
    name: "cart-components-CartItemCodesPromo___de"
  }, {
    path: "/en/account/address/new",
    component: _b92ac7f0,
    name: "account-address-new___en"
  }, {
    path: "/en/account/components/GetCardCadeau",
    component: _a4235820,
    name: "account-components-GetCardCadeau___en"
  }, {
    path: "/en/account/service/tickets",
    component: _0af8dcda,
    name: "account-service-tickets___en"
  }, {
    path: "/en/cart/components/CartItemCodesPromo",
    component: _78147334,
    name: "cart-components-CartItemCodesPromo___en"
  }, {
    path: "/fr/account/address/new",
    component: _b92ac7f0,
    name: "account-address-new___fr"
  }, {
    path: "/fr/account/components/GetCardCadeau",
    component: _a4235820,
    name: "account-components-GetCardCadeau___fr"
  }, {
    path: "/fr/account/service/tickets",
    component: _0af8dcda,
    name: "account-service-tickets___fr"
  }, {
    path: "/fr/cart/components/CartItemCodesPromo",
    component: _78147334,
    name: "cart-components-CartItemCodesPromo___fr"
  }, {
    path: "/de/account/components/GetCardCadeau/GetCardCadeauShow",
    component: _7bb270d8,
    name: "account-components-GetCardCadeau-GetCardCadeauShow___de"
  }, {
    path: "/de/account/components/GetCardCadeau/GetCardCadeauStep1",
    component: _44599a5e,
    name: "account-components-GetCardCadeau-GetCardCadeauStep1___de"
  }, {
    path: "/de/account/components/GetCardCadeau/GetCardCadeauStep2",
    component: _4467b1df,
    name: "account-components-GetCardCadeau-GetCardCadeauStep2___de"
  }, {
    path: "/de/cart/components/CartItemCodesPromo/CodePromoItem",
    component: _0f028c17,
    name: "cart-components-CartItemCodesPromo-CodePromoItem___de"
  }, {
    path: "/en/account/components/GetCardCadeau/GetCardCadeauShow",
    component: _7bb270d8,
    name: "account-components-GetCardCadeau-GetCardCadeauShow___en"
  }, {
    path: "/en/account/components/GetCardCadeau/GetCardCadeauStep1",
    component: _44599a5e,
    name: "account-components-GetCardCadeau-GetCardCadeauStep1___en"
  }, {
    path: "/en/account/components/GetCardCadeau/GetCardCadeauStep2",
    component: _4467b1df,
    name: "account-components-GetCardCadeau-GetCardCadeauStep2___en"
  }, {
    path: "/en/cart/components/CartItemCodesPromo/CodePromoItem",
    component: _0f028c17,
    name: "cart-components-CartItemCodesPromo-CodePromoItem___en"
  }, {
    path: "/fr/account/components/GetCardCadeau/GetCardCadeauShow",
    component: _7bb270d8,
    name: "account-components-GetCardCadeau-GetCardCadeauShow___fr"
  }, {
    path: "/fr/account/components/GetCardCadeau/GetCardCadeauStep1",
    component: _44599a5e,
    name: "account-components-GetCardCadeau-GetCardCadeauStep1___fr"
  }, {
    path: "/fr/account/components/GetCardCadeau/GetCardCadeauStep2",
    component: _4467b1df,
    name: "account-components-GetCardCadeau-GetCardCadeauStep2___fr"
  }, {
    path: "/fr/cart/components/CartItemCodesPromo/CodePromoItem",
    component: _0f028c17,
    name: "cart-components-CartItemCodesPromo-CodePromoItem___fr"
  }, {
    path: "/de/cart/components/payments/alma/AlmaForm",
    component: _1a805346,
    name: "cart-components-payments-alma-AlmaForm___de"
  }, {
    path: "/de/cart/components/payments/alma/AlmaFormHeader",
    component: _a4656e1a,
    name: "cart-components-payments-alma-AlmaFormHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/BancCardHipay",
    component: _25482451,
    name: "cart-components-payments-hipay-BancCardHipay___de"
  }, {
    path: "/de/cart/components/payments/hipay/Bancontact",
    component: _aab9ec0e,
    name: "cart-components-payments-hipay-Bancontact___de"
  }, {
    path: "/de/cart/components/payments/hipay/Giropay",
    component: _00bc41b7,
    name: "cart-components-payments-hipay-Giropay___de"
  }, {
    path: "/de/cart/components/payments/hipay/Ideal",
    component: _660e9e23,
    name: "cart-components-payments-hipay-Ideal___de"
  }, {
    path: "/de/cart/components/payments/hipay/Sofort",
    component: _77a55e22,
    name: "cart-components-payments-hipay-Sofort___de"
  }, {
    path: "/de/cart/components/payments/klarna/KlarnaForm",
    component: _4adcdf06,
    name: "cart-components-payments-klarna-KlarnaForm___de"
  }, {
    path: "/de/cart/components/payments/klarna/KlarnaFormHeader",
    component: _4ffbf69a,
    name: "cart-components-payments-klarna-KlarnaFormHeader___de"
  }, {
    path: "/de/cart/components/payments/paypal/PayPalForm",
    component: _00908f26,
    name: "cart-components-payments-paypal-PayPalForm___de"
  }, {
    path: "/de/cart/components/payments/paypal/PayPalForm-old",
    component: _3048be00,
    name: "cart-components-payments-paypal-PayPalForm-old___de"
  }, {
    path: "/de/cart/components/payments/paypal/PaypalFormHeader",
    component: _3634dcf3,
    name: "cart-components-payments-paypal-PaypalFormHeader___de"
  }, {
    path: "/de/cart/components/payments/stripe/StripeForm",
    component: _522a63f4,
    name: "cart-components-payments-stripe-StripeForm___de"
  }, {
    path: "/de/cart/components/payments/stripe/StripeFormHeader",
    component: _57a0b3b3,
    name: "cart-components-payments-stripe-StripeFormHeader___de"
  }, {
    path: "/en/cart/components/payments/alma/AlmaForm",
    component: _1a805346,
    name: "cart-components-payments-alma-AlmaForm___en"
  }, {
    path: "/en/cart/components/payments/alma/AlmaFormHeader",
    component: _a4656e1a,
    name: "cart-components-payments-alma-AlmaFormHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/BancCardHipay",
    component: _25482451,
    name: "cart-components-payments-hipay-BancCardHipay___en"
  }, {
    path: "/en/cart/components/payments/hipay/Bancontact",
    component: _aab9ec0e,
    name: "cart-components-payments-hipay-Bancontact___en"
  }, {
    path: "/en/cart/components/payments/hipay/Giropay",
    component: _00bc41b7,
    name: "cart-components-payments-hipay-Giropay___en"
  }, {
    path: "/en/cart/components/payments/hipay/Ideal",
    component: _660e9e23,
    name: "cart-components-payments-hipay-Ideal___en"
  }, {
    path: "/en/cart/components/payments/hipay/Sofort",
    component: _77a55e22,
    name: "cart-components-payments-hipay-Sofort___en"
  }, {
    path: "/en/cart/components/payments/klarna/KlarnaForm",
    component: _4adcdf06,
    name: "cart-components-payments-klarna-KlarnaForm___en"
  }, {
    path: "/en/cart/components/payments/klarna/KlarnaFormHeader",
    component: _4ffbf69a,
    name: "cart-components-payments-klarna-KlarnaFormHeader___en"
  }, {
    path: "/en/cart/components/payments/paypal/PayPalForm",
    component: _00908f26,
    name: "cart-components-payments-paypal-PayPalForm___en"
  }, {
    path: "/en/cart/components/payments/paypal/PayPalForm-old",
    component: _3048be00,
    name: "cart-components-payments-paypal-PayPalForm-old___en"
  }, {
    path: "/en/cart/components/payments/paypal/PaypalFormHeader",
    component: _3634dcf3,
    name: "cart-components-payments-paypal-PaypalFormHeader___en"
  }, {
    path: "/en/cart/components/payments/stripe/StripeForm",
    component: _522a63f4,
    name: "cart-components-payments-stripe-StripeForm___en"
  }, {
    path: "/en/cart/components/payments/stripe/StripeFormHeader",
    component: _57a0b3b3,
    name: "cart-components-payments-stripe-StripeFormHeader___en"
  }, {
    path: "/fr/cart/components/payments/alma/AlmaForm",
    component: _1a805346,
    name: "cart-components-payments-alma-AlmaForm___fr"
  }, {
    path: "/fr/cart/components/payments/alma/AlmaFormHeader",
    component: _a4656e1a,
    name: "cart-components-payments-alma-AlmaFormHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/BancCardHipay",
    component: _25482451,
    name: "cart-components-payments-hipay-BancCardHipay___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Bancontact",
    component: _aab9ec0e,
    name: "cart-components-payments-hipay-Bancontact___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Giropay",
    component: _00bc41b7,
    name: "cart-components-payments-hipay-Giropay___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Ideal",
    component: _660e9e23,
    name: "cart-components-payments-hipay-Ideal___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Sofort",
    component: _77a55e22,
    name: "cart-components-payments-hipay-Sofort___fr"
  }, {
    path: "/fr/cart/components/payments/klarna/KlarnaForm",
    component: _4adcdf06,
    name: "cart-components-payments-klarna-KlarnaForm___fr"
  }, {
    path: "/fr/cart/components/payments/klarna/KlarnaFormHeader",
    component: _4ffbf69a,
    name: "cart-components-payments-klarna-KlarnaFormHeader___fr"
  }, {
    path: "/fr/cart/components/payments/paypal/PayPalForm",
    component: _00908f26,
    name: "cart-components-payments-paypal-PayPalForm___fr"
  }, {
    path: "/fr/cart/components/payments/paypal/PayPalForm-old",
    component: _3048be00,
    name: "cart-components-payments-paypal-PayPalForm-old___fr"
  }, {
    path: "/fr/cart/components/payments/paypal/PaypalFormHeader",
    component: _3634dcf3,
    name: "cart-components-payments-paypal-PaypalFormHeader___fr"
  }, {
    path: "/fr/cart/components/payments/stripe/StripeForm",
    component: _522a63f4,
    name: "cart-components-payments-stripe-StripeForm___fr"
  }, {
    path: "/fr/cart/components/payments/stripe/StripeFormHeader",
    component: _57a0b3b3,
    name: "cart-components-payments-stripe-StripeFormHeader___fr"
  }, {
    path: "/de/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader",
    component: _7aec7f8f,
    name: "cart-components-payments-hipay-BancCardHipay-BancCardHipayHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/BancCardHipay/BankCardForm",
    component: _6aeb0d41,
    name: "cart-components-payments-hipay-BancCardHipay-BankCardForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Bancontact/BanContactForm",
    component: _0803c6fe,
    name: "cart-components-payments-hipay-Bancontact-BanContactForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Bancontact/BancontactHeader",
    component: _36732032,
    name: "cart-components-payments-hipay-Bancontact-BancontactHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/Giropay/GiropayForm",
    component: _508d1f92,
    name: "cart-components-payments-hipay-Giropay-GiropayForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Giropay/GiropayHeader",
    component: _1b20335b,
    name: "cart-components-payments-hipay-Giropay-GiropayHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/Ideal/IdealForm",
    component: _5ceec3ea,
    name: "cart-components-payments-hipay-Ideal-IdealForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Ideal/IdealHeader",
    component: _d4abbc9a,
    name: "cart-components-payments-hipay-Ideal-IdealHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/Sofort/SofortForm",
    component: _f232ff44,
    name: "cart-components-payments-hipay-Sofort-SofortForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Sofort/SofortHeader",
    component: _20a49127,
    name: "cart-components-payments-hipay-Sofort-SofortHeader___de"
  }, {
    path: "/en/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader",
    component: _7aec7f8f,
    name: "cart-components-payments-hipay-BancCardHipay-BancCardHipayHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/BancCardHipay/BankCardForm",
    component: _6aeb0d41,
    name: "cart-components-payments-hipay-BancCardHipay-BankCardForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Bancontact/BanContactForm",
    component: _0803c6fe,
    name: "cart-components-payments-hipay-Bancontact-BanContactForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Bancontact/BancontactHeader",
    component: _36732032,
    name: "cart-components-payments-hipay-Bancontact-BancontactHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/Giropay/GiropayForm",
    component: _508d1f92,
    name: "cart-components-payments-hipay-Giropay-GiropayForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Giropay/GiropayHeader",
    component: _1b20335b,
    name: "cart-components-payments-hipay-Giropay-GiropayHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/Ideal/IdealForm",
    component: _5ceec3ea,
    name: "cart-components-payments-hipay-Ideal-IdealForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Ideal/IdealHeader",
    component: _d4abbc9a,
    name: "cart-components-payments-hipay-Ideal-IdealHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/Sofort/SofortForm",
    component: _f232ff44,
    name: "cart-components-payments-hipay-Sofort-SofortForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Sofort/SofortHeader",
    component: _20a49127,
    name: "cart-components-payments-hipay-Sofort-SofortHeader___en"
  }, {
    path: "/fr/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader",
    component: _7aec7f8f,
    name: "cart-components-payments-hipay-BancCardHipay-BancCardHipayHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/BancCardHipay/BankCardForm",
    component: _6aeb0d41,
    name: "cart-components-payments-hipay-BancCardHipay-BankCardForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Bancontact/BanContactForm",
    component: _0803c6fe,
    name: "cart-components-payments-hipay-Bancontact-BanContactForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Bancontact/BancontactHeader",
    component: _36732032,
    name: "cart-components-payments-hipay-Bancontact-BancontactHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Giropay/GiropayForm",
    component: _508d1f92,
    name: "cart-components-payments-hipay-Giropay-GiropayForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Giropay/GiropayHeader",
    component: _1b20335b,
    name: "cart-components-payments-hipay-Giropay-GiropayHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Ideal/IdealForm",
    component: _5ceec3ea,
    name: "cart-components-payments-hipay-Ideal-IdealForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Ideal/IdealHeader",
    component: _d4abbc9a,
    name: "cart-components-payments-hipay-Ideal-IdealHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Sofort/SofortForm",
    component: _f232ff44,
    name: "cart-components-payments-hipay-Sofort-SofortForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Sofort/SofortHeader",
    component: _20a49127,
    name: "cart-components-payments-hipay-Sofort-SofortHeader___fr"
  }, {
    path: "/de/account/service/request/:productAttribute?",
    component: _cc099e96,
    name: "account-service-request-productAttribute___de"
  }, {
    path: "/de/account/service/tickets/:idTickets",
    component: _31618348,
    name: "account-service-tickets-idTickets___de"
  }, {
    path: "/en/account/service/request/:productAttribute?",
    component: _cc099e96,
    name: "account-service-request-productAttribute___en"
  }, {
    path: "/en/account/service/tickets/:idTickets",
    component: _31618348,
    name: "account-service-tickets-idTickets___en"
  }, {
    path: "/fr/account/service/request/:productAttribute?",
    component: _cc099e96,
    name: "account-service-request-productAttribute___fr"
  }, {
    path: "/fr/account/service/tickets/:idTickets",
    component: _31618348,
    name: "account-service-tickets-idTickets___fr"
  }, {
    path: "/de/account/address/:idAddress",
    component: _2a730ebc,
    name: "account-address-idAddress___de"
  }, {
    path: "/de/account/orders/:id",
    component: _8240a446,
    name: "account-orders-id___de"
  }, {
    path: "/de/account/return/:id",
    component: _07e915ab,
    name: "account-return-id___de"
  }, {
    path: "/de/account/returns/:id",
    component: _bbde7998,
    name: "account-returns-id___de"
  }, {
    path: "/de/content/faq/:permalink",
    component: _8e39c4f4,
    name: "content-faq-permalink___de"
  }, {
    path: "/en/account/address/:idAddress",
    component: _2a730ebc,
    name: "account-address-idAddress___en"
  }, {
    path: "/en/account/orders/:id",
    component: _8240a446,
    name: "account-orders-id___en"
  }, {
    path: "/en/account/return/:id",
    component: _07e915ab,
    name: "account-return-id___en"
  }, {
    path: "/en/account/returns/:id",
    component: _bbde7998,
    name: "account-returns-id___en"
  }, {
    path: "/en/content/faq/:permalink",
    component: _8e39c4f4,
    name: "content-faq-permalink___en"
  }, {
    path: "/fr/account/address/:idAddress",
    component: _2a730ebc,
    name: "account-address-idAddress___fr"
  }, {
    path: "/fr/account/orders/:id",
    component: _8240a446,
    name: "account-orders-id___fr"
  }, {
    path: "/fr/account/return/:id",
    component: _07e915ab,
    name: "account-return-id___fr"
  }, {
    path: "/fr/account/returns/:id",
    component: _bbde7998,
    name: "account-returns-id___fr"
  }, {
    path: "/fr/content/faq/:permalink",
    component: _8e39c4f4,
    name: "content-faq-permalink___fr"
  }, {
    path: "/de/account/return/:id?/confirmed",
    component: _43089938,
    name: "account-return-id-confirmed___de"
  }, {
    path: "/en/account/return/:id?/confirmed",
    component: _43089938,
    name: "account-return-id-confirmed___en"
  }, {
    path: "/fr/account/return/:id?/confirmed",
    component: _43089938,
    name: "account-return-id-confirmed___fr"
  }, {
    path: "/de/cart/thanks/:state?/:idorder?",
    component: _4c8738c2,
    name: "cart-thanks-state-idorder___de"
  }, {
    path: "/en/cart/thanks/:state?/:idorder?",
    component: _4c8738c2,
    name: "cart-thanks-state-idorder___en"
  }, {
    path: "/fr/cart/thanks/:state?/:idorder?",
    component: _4c8738c2,
    name: "cart-thanks-state-idorder___fr"
  }, {
    path: "/de/account/:tab",
    component: _fcde63b4,
    name: "account-tab___de"
  }, {
    path: "/de/authenticate/:route?",
    component: _45a295ae,
    name: "authenticate-route___de"
  }, {
    path: "/de/blog/:uid?",
    component: _1851e883,
    name: "blog-uid___de"
  }, {
    path: "/de/blogs/:permalink?",
    component: _355d8715,
    name: "blogs-permalink___de"
  }, {
    path: "/de/geschaft/:permalink",
    component: _533dbb8a,
    name: "shop-permalink___de"
  }, {
    path: "/de/lookbooks/:permalink",
    component: _7bbc03fb,
    name: "lookbooks-permalink___de"
  }, {
    path: "/de/product/:product?",
    component: _6e8f0bbb,
    name: "product-product___de"
  }, {
    path: "/de/referral/:code?",
    component: _7d180885,
    name: "referral-code___de"
  }, {
    path: "/de/shop-the-look/:product?",
    component: _dd22d050,
    name: "shop-the-look-product___de"
  }, {
    path: "/en/account/:tab",
    component: _fcde63b4,
    name: "account-tab___en"
  }, {
    path: "/en/authenticate/:route?",
    component: _45a295ae,
    name: "authenticate-route___en"
  }, {
    path: "/en/blog/:uid?",
    component: _1851e883,
    name: "blog-uid___en"
  }, {
    path: "/en/blogs/:permalink?",
    component: _355d8715,
    name: "blogs-permalink___en"
  }, {
    path: "/en/lookbooks/:permalink",
    component: _7bbc03fb,
    name: "lookbooks-permalink___en"
  }, {
    path: "/en/product/:product?",
    component: _6e8f0bbb,
    name: "product-product___en"
  }, {
    path: "/en/referral/:code?",
    component: _7d180885,
    name: "referral-code___en"
  }, {
    path: "/en/shop-the-look/:product?",
    component: _dd22d050,
    name: "shop-the-look-product___en"
  }, {
    path: "/en/shop/:permalink",
    component: _533dbb8a,
    name: "shop-permalink___en"
  }, {
    path: "/fr/account/:tab",
    component: _fcde63b4,
    name: "account-tab___fr"
  }, {
    path: "/fr/authenticate/:route?",
    component: _45a295ae,
    name: "authenticate-route___fr"
  }, {
    path: "/fr/blog/:uid?",
    component: _1851e883,
    name: "blog-uid___fr"
  }, {
    path: "/fr/blogs/:permalink?",
    component: _355d8715,
    name: "blogs-permalink___fr"
  }, {
    path: "/fr/boutique/:permalink",
    component: _533dbb8a,
    name: "shop-permalink___fr"
  }, {
    path: "/fr/lookbooks/:permalink",
    component: _7bbc03fb,
    name: "lookbooks-permalink___fr"
  }, {
    path: "/fr/product/:product?",
    component: _6e8f0bbb,
    name: "product-product___fr"
  }, {
    path: "/fr/referral/:code?",
    component: _7d180885,
    name: "referral-code___fr"
  }, {
    path: "/fr/shop-the-look/:product?",
    component: _dd22d050,
    name: "shop-the-look-product___fr"
  }, {
    path: "/de/account/:tab?/:tab2",
    component: _7e7fdc70,
    name: "account-tab-tab2___de"
  }, {
    path: "/en/account/:tab?/:tab2",
    component: _7e7fdc70,
    name: "account-tab-tab2___en"
  }, {
    path: "/fr/account/:tab?/:tab2",
    component: _7e7fdc70,
    name: "account-tab-tab2___fr"
  }, {
    path: "/de/:cat",
    component: _036f07f6,
    name: "cat___de"
  }, {
    path: "/en/:cat",
    component: _036f07f6,
    name: "cat___en"
  }, {
    path: "/fr/:cat",
    component: _036f07f6,
    name: "cat___fr"
  }, {
    path: "/de/:cat/:product",
    component: _b3596a66,
    name: "cat-product___de"
  }, {
    path: "/en/:cat/:product",
    component: _b3596a66,
    name: "cat-product___en"
  }, {
    path: "/fr/:cat/:product",
    component: _b3596a66,
    name: "cat-product___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
